body {
  background: #f9f9f9 url(../public/img/backgroud_general_generated.png);
}

.App {
  max-width: 1920px;
  margin: auto;
}

.success {
  background-color: #3fa90c;
}

.success-button:hover {
  background-color: #55ce1b !important;
}

.no-margin {
  margin: 0 !important;
}

.top-1em {
  margin-top: 1em;
}

.bottom-1em {
  margin-bottom: 1em;
}

.container-1200 {
  max-width: 1200px;
  margin: auto;
}

.container-1700 {
  padding: 1em;
  margin-top: 1em;
  max-width: 1700px;
  margin-left: 1em;
  margin-right: 1em;
  background-color: #ffffffcc;
}

.inline-menu>.ant-menu-item-selected {
  color: #000000 !important;
}

.inline-menu>.ant-menu-item-selected::after {
  border: none !important;
}

.inline-menu>.ant-menu-item:after {
  border: none !important;
}

.inline-menu>.ant-menu-item:hover {
  background-color: #c4c4c429;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-card {
  border-radius: 0 !important;
}

.ant-typography pre {
  border-radius: 0 !important;
}

.ant-btn {
  border-radius: 0 !important;
}

.ant-select-selector {
  border-radius: 0 !important;
}

.ant-pagination-item {
  border-radius: 0 !important;
}

.ant-checkbox-inner {
  border-radius: 0 !important;
}

.ant-tag {
  border-radius: 0 !important;
}

.ant-input {
  border-radius: 0 !important;
}

.ant-input-number-group-addon {
  border-radius: 0 !important;
}

.ant-input-number {
  border-radius: 0 !important;
}

.ant-input-affix-wrapper {
  border-radius: 0 !important;
}

.ant-picker {
  border-radius: 0 !important;
}

.ant-picker-panel-container {
  border-radius: 0 !important;
}

.ant-breadcrumb>ol>li>a:hover {
  border-radius: 0;
  background-color: #c4c4c429;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.container-1800 {
  max-width: 1800px;
  margin: auto;
}

.title-bg {
  background: #f8f8f8;
  padding: 10px 5px;
  margin: 0px 0px 20px 0px;
  border-bottom: 1px dotted #a9a9a9;
  border-top: 1px dotted #a9a9a9;
}

.ant-layout-header {
  padding-inline: 0px;
}

.red-checkbox>.ant-checkbox>.ant-checkbox-inner {
  background-color: #cb1313 !important;
  border-color: #cb1313 !important;
}

.red-checkbox:hover>.ant-checkbox>.ant-checkbox-inner {
  background-color: #cb1313 !important;
  border-color: #cb1313 !important;
}

.orange-checkbox>.ant-checkbox>.ant-checkbox-inner {
  background-color: #ce512c !important;
  border-color: #ce512c !important;
}

.orange-checkbox:hover>.ant-checkbox>.ant-checkbox-inner {
  background-color: #ce512c !important;
  border-color: #ce512c !important;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.btn-green {
  background-color: green;
}

.btn-green:hover {
  background-color: #45a945 !important;
}